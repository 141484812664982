import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
//
import NavList from './NavList';
import SvgIconStyle from '../../SvgIconStyle';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const { user } = useAuth();
  const salesperson = {
    title: 'salesperson',
    path: PATH_DASHBOARD.salesperson.list,
    icon: <SvgIconStyle src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: 1, height: 1 }} />,
  };

  const OrgDetails = {
    title: 'organizations',
    path: PATH_DASHBOARD.organization.list,
    icon: <SvgIconStyle src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: 1, height: 1 }} />,
  };

  return (
    <Stack direction="row" justifyContent="center" sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}>
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {user.roleId === 1 && (
          <NavList
            key={OrgDetails.title + OrgDetails.path}
            data={OrgDetails}
            depth={1}
            hasChildren={!!OrgDetails.children}
          />
        )}

        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              <NavList key={list.title + list.path} data={list} depth={1} hasChildren={!!list.children} />
            ))}
          </Stack>
        ))}

        {user.roleId !== 3 && (
          <NavList
            key={salesperson.title + salesperson.path}
            data={salesperson}
            depth={1}
            hasChildren={!!salesperson.children}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
