/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Grid, Stack, Typography, Button, CircularProgress, Container } from '@mui/material';
// utils
import { fCurrency, fData } from '../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../components/hook-form';
import { packageService } from '../../services/packages';
import Iconify from '../../components/Iconify';
import { productService } from '../../services/product';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import { adminService } from '../../services/adminPage';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

PackageNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  isView: PropTypes.bool,
  currentEvent: PropTypes.object,
};

export default function PackageNewEditForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const { id = '' } = useParams();
  const isEdit = pathname.includes('edit');
  const isView = pathname.includes('view');
  const [currentPackage, setCurrentPackage] = useState({});
  const { user } = useAuth();


  const getPackageById = async () => {
    try {
      const res = await packageService.getPackageById(id);
      setCurrentPackage(res.data);
      reset({
        ...res.data,
        products: res.data.products || [
          { productName: '', quantity: 1, price: 0, trialDays: 0, totalPrice: 0, type: 'NONE', productId: '' },
        ],
      });
      setIsLoading(false);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit && isView) {
      getPackageById();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const defaultValues = useMemo(
    () => ({
      id: currentPackage?.id || '',
      packageTitle: currentPackage?.packageTitle || (isEdit ? '' : 'New Package'),
      defaultDiscount: currentPackage?.defaultDiscount || 0.0,
      finalPackageAmount: currentPackage?.finalPackageAmount || 0,
      products: currentPackage?.products || [
        { productName: '', quantity: 1, price: 0, trialDays: 0, totalPrice: 0, type: 'NONE', productId: '' },
      ],
      status: currentPackage?.status || 0,
    }),
    [currentPackage, isEdit]
  );

  const getProducts = async () => {
    try {
      const res = await productService.getProducts();
      setProducts(res.data);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  const getProductsAdmin = async (id) => {
    try {
      const res = await adminService.getProductsListAdmin(id);
      setProducts(res.data);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  // getProductsListAdmin
  console.log(currentPackage, 'currentPackage');
  console.log(user?.roleId, 'eeeeeeeeee')
  useEffect(() => {

    if (user?.roleId === 1) {
      getProductsAdmin(currentPackage?.organizationId);
    } else {
      getProducts();
    }
  }, [user, currentPackage]);

  const methods = useForm({
    resolver: yupResolver(Yup.object().shape({})), // empty schema since we're just viewing
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const selectedProduct = products?.find((product) => product.id === value);
    const newProducts = [...values.products];
    newProducts[index][name] = selectedProduct?.name;
    newProducts[index].productId = selectedProduct?.id;
    newProducts[index].price = selectedProduct?.prices[0]?.amount / 100;
    newProducts[index].quantity = 1;
    newProducts[index].type = selectedProduct?.prices[0]?.recurring === null ? 'one_time' : 'recurring';
    newProducts[index].totalPrice = (selectedProduct?.prices[0]?.amount * newProducts[index].quantity) / 100;
    setValue('products', newProducts);
  };

  const updatePackage = async (data) => {
    const body = {
      id: data.id,
      packageTitle: data.packageTitle,
      defaultDiscount: data.defaultDiscount,
      finalPackageAmount: data.finalPackageAmount,
      products: data.products,
      status: data.status,
    };
    try {
      const res = await packageService.updatePackage(body);
      enqueueSnackbar(res.message);
      navigate(PATH_DASHBOARD.packages.list);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const onSubmit = (data) => {
    if (isEdit) {
      updatePackage(data);
    } else {
      // createPackage(data);
    }
  };

  useEffect(() => {
    if (isEdit && currentPackage) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentPackage]);

  return (
    <>
      <Page title="Package : View Package">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="View Package"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Package', href: PATH_DASHBOARD.packages.list },
              { name: currentPackage?.packageTitle || 'View Package' },
            ]}
          />

          <FormProvider methods={methods}>
            <Grid container spacing={3} sx={{ minHeight: '50vh', justifyContent: 'center', alignItems: 'center' }}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Grid item xs={12}>
                  <Card sx={{ p: 3 }}>
                    <Box
                      sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                      }}
                    >
                      <Stack direction="row" spacing={3}>
                        <RHFTextField name="packageTitle" label="Package Title" disabled />
                        <RHFTextField name="defaultDiscount" label="Default Discount" disabled />
                      </Stack>

                      <Stack spacing={3}>
                        <Typography variant="h6"> Package Item(s)</Typography>
                        <Stack spacing={3}>
                          {values?.products?.map((product, index) => (
                            <Stack key={product.productId} direction="row" spacing={3}>
                              <RHFSelect
                                name="productName"
                                sx={{ width: '250%' }}
                                label="Product"
                                value={product.productId}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) => handleChange(index, e)}
                                disabled
                              >
                                <option value="">None</option>
                                {products?.map((product) => (
                                  <option key={product.id} value={product.id}>
                                    {product.name}
                                  </option>
                                ))}
                              </RHFSelect>
                              <RHFTextField
                                sx={{ width: '70%' }}
                                disabled
                                name="price"
                                label="Price"
                                value={fCurrency(product.price.toFixed(2))}
                              />
                              <RHFTextField
                                sx={{ width: '70%' }}
                                name="quantity"
                                label="Quantity"
                                value={product.quantity}
                                disabled
                              />
                              <RHFTextField
                                disabled
                                sx={{ width: '70%' }}
                                name="totalItemPrice"
                                label="Total Item Price"
                                value={fCurrency(product.totalPrice.toFixed(2))}
                              />
                              <RHFTextField
                                disabled
                                name="type"
                                label="Type"
                                value={product.type}
                                sx={{ width: '70%' }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <RHFTextField
                                name="trialDays"
                                sx={{ width: '70%' }}
                                label="Trial Days"
                                disabled
                                value={product.trialDays}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    </Box>
                  </Card>
                </Grid>
              )}
            </Grid>
          </FormProvider>
        </Container>
      </Page>
    </>
  );
}
