/* eslint-disable prettier/prettier */
import axios from "../utils/axios";
import { config } from "./config";


const getDashboardCount = async()=>{
    const data = await axios.get("/api/v1/admin/get_dashboard_count",config)
    return data.data
}

const getorganisationList = async()=>{
    const data = await axios.get("/api/v1/admin/get_organization_list",config)
    return data.data
}

const getQuotesListAdmin = async(id)=>{
    const data = await axios.get(`/api/v1/admin/get_quote_list?organizationId=${id}`,config)
    return data.data
}

const getEventListAdmin = async(id)=>{
    const data = await axios.get(`/api/v1/admin/get_event_list?organizationId=${id}`,config)
    return data.data
}

const getPackagestListAdmin = async(id)=>{
    const data = await axios.get(`/api/v1/admin/get_package_list?organizationId=${id}`,config)
    return data.data
}

const getSalesPersonListAdmin = async(id)=>{
    const data = await axios.get(`/api/v1/admin/get_sales_person_list?organizationId=${id}`,config)
    return data.data
}

const getOrgCountDetailsAdmin = async(id)=>{
    const data = await axios.get(`/api/v1/admin/get_dashboard_count_organization?organizationId=${id}`,config)
    return data.data
}

const getProductsListAdmin = async(id)=>{
    const data = await axios.get(`/api/v1/admin/get_product_list?organizationId=${id}`,config)
    return data.data
}


const blockOrganisationAdmin = async(id)=>{
    const data = await axios.post(`/api/v1/admin/block_organization/${id}`, {}, config)
    return data.data
}

const UnblockOrganisationAdmin = async(id)=>{
    const data = await axios.post(`/api/v1/admin/unblock_organization/${id}`, {}, config)
    return data.data
}


 export const adminService = {
    getDashboardCount,
    getorganisationList,
    getQuotesListAdmin,
    getEventListAdmin,
    getPackagestListAdmin,
    getSalesPersonListAdmin,
    blockOrganisationAdmin,
    UnblockOrganisationAdmin,
    getOrgCountDetailsAdmin,
    getProductsListAdmin
}