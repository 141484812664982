import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  
  const { isAuthenticated, isInitialized , user } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ user?.roleId === 1 ? PATH_DASHBOARD.organization.list :  PATH_DASHBOARD.quotes.list} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
