

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, Container } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock
import { countries } from '../../_mock';
// components
import Label from '../../components/Label';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
import { eventService } from '../../services/events';
import { salesPersonService } from '../../services/salesPerson';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';
import {formatPhoneNumber} from "../../utils/numberformatter"

// ----------------------------------------------------------------------

SalespersonNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentEvent: PropTypes.object,
};

export default function SalespersonNewEditForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const { id = '' } = useParams();

  const isEdit = pathname.includes('edit');

  console.log(id);

  const [currentSalesperson, setCurrentSalesperson] = useState({});

  const getSalespersonById = () => {
    salesPersonService
      .getSalespersonById(id)
      .then((res) => {
        setCurrentSalesperson(res.data);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    getSalespersonById();
  }, [id]);

  const NewSalespersonSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(3, 'First Name should be atleast 3 characters')
      .trim(),
    phone: Yup.string()
      .required('Phone Number is required')
      .min(10, 'Enter a valid phone number')
      .max(10, 'Enter a valid phone number')
      .trim(),
    email: Yup.string().required('Email is required').email('Please Enter a valid Email').trim(),
  });

  const defaultValues = useMemo(
    () => ({
      id: currentSalesperson?.id || '',
      firstName: currentSalesperson?.firstName || '',
      lastName: currentSalesperson?.lastName || '',
      phone: currentSalesperson?.phone || '',
      email: currentSalesperson?.email || '',
      status: currentSalesperson?.status ?? '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSalesperson]
  );

  const methods = useForm({
    resolver: yupResolver(NewSalespersonSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentSalesperson) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentSalesperson]);

  return (
    <Page title="Salesperson : Create a new Salesperson">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Salesperson View"
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Salesperson', href: PATH_DASHBOARD.packages.root },
            { name: 'View' },
          ]}
        />

        <FormProvider methods={methods}>
          <Card sx={{ p: 3, width: '50%' }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                width: '100%',
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="firstName" label="First Name" disabled />
              <RHFTextField name="lastName" label="Last Name" disabled />
              <RHFTextField name="phone" label="Phone" value={formatPhoneNumber(values.phone)} onChange={(e)=>setValue("phone", e.target.value)} disabled/>

              <RHFTextField name="email" label="Email" disabled />
            </Box>
          </Card>
        </FormProvider>
      </Container>
    </Page>
  );
}
