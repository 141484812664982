import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const accessTokenExpiry = localStorage.getItem('accessTokenExpiry');

        if (accessToken && isValidToken(accessToken, accessTokenExpiry)) {
          setSession(accessToken, accessTokenExpiry);

          const response = await axios.get('/api/v1/user/profile', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              // "Content-Type":'application/json',
              'x-api-key': process.env.REACT_APP_X_API_KEY || 'quotemanagementtooldd52ea2d-4567-4956-9d19-35a7e77a1c19',
              platform: process.env.REACT_APP_PLAFORM || 'web',
            },
          });
          const user = response.data.data;

          // if (Number(user?.isDeleted)=== 0 && Number(user?.status) === 1){
          //   setSession(null, null)
          // }
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user,
              },
            });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [refresh]);

  const login = async (email, password) => {
    const data = { email, password };
    const result = await axios({
      method: 'post',
      url: '/api/v1/auth/user/login',
      headers: {
        'x-api-key': 'quotemanagementtooldd52ea2d-4567-4956-9d19-35a7e77a1c19',
        platform: 'web',
      },
      data,
    });

    const accessToken = result?.data?.data?.token?.accessToken;
    const accessTokenExpiry = result?.data?.data?.accessTokenExpiry;
    const user = result?.data?.data;
    setSession(accessToken, accessTokenExpiry, user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (data) => {
    const result = await axios({
      method: 'post',
      url: '/api/v1/auth/user/signup_organization',
      headers: {
        'x-api-key': 'quotemanagementtooldd52ea2d-4567-4956-9d19-35a7e77a1c19',
        platform: 'web',
      },
      data,
    });
    // console.log(result,'Register-Response')
    const accessToken = result?.data?.data?.token?.accessToken;
    const accessTokenExpiry = result?.data?.data?.accessTokenExpiry;
    const user = result?.data?.data;

    setSession(accessToken, accessTokenExpiry);
    // const { accessToken, user } = response.data;

    // localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null);
    // dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        setRefresh
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
