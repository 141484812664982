import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name:'products',
    initialState:{
        isLoading:true,
        productList:[]
    },
    reducers:{
        setProduct : (state,action)=>{
            
            state.productList = action.payload
        },
        setIsLoading : (state,action)=>{
            
            state.isLoading = action.payload
        }
    }
})

export const {setProduct,setIsLoading} = productSlice.actions;

export default productSlice.reducer