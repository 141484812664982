/* eslint-disable prettier/prettier */
import axios from "../utils/axios";
import { config } from "./config";


const getProducts = async()=>{
    const data = await axios.get("/api/v1/user/get_stripe_products_list",config)
    return data.data
}

 export const productService = {
    getProducts,
}