import PropTypes from 'prop-types';
// @mui
import { List, Box } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../SvgIconStyle';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const salesperson = {
    title: 'salesperson',
    path: PATH_DASHBOARD.salesperson.list,
    icon: <SvgIconStyle src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: 1, height: 1 }} />,
  };

 const OrgDetails = {
    title: 'organizations',
    path: PATH_DASHBOARD.organization.list,
    icon: <SvgIconStyle src={`/assets/icons/navbar/ic_user.svg`} sx={{ width: 1, height: 1 }} />,
  };


  return (

    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>
                  
          
      {user.roleId === 1 && (
          <NavList
            key={OrgDetails.title + OrgDetails.path}
            data={OrgDetails}
            depth={1}
            hasChildren={!!OrgDetails.children}
          />
        )}

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
          
        {user.roleId !== 3 && (
          <NavList
            key={salesperson.title + salesperson.path}
            data={salesperson}
            depth={1}
            hasChildren={!!salesperson.children}
          />
        )}
        </List>
      ))}
    </Box>
  );
}
