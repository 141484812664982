// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken, exp) => {
  if (!accessToken) {
    return false;
  }
  // const decoded = jwtDecode(accessToken);
  // console.log(accessToken, ': accessToken', exp, ':EXP');
  const currentTime = Date.now();
  exp *= 1000;
  // console.log(currentTime);
  // console.log(exp);
  // console.log(exp > currentTime);

  return exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Number(Date.now()/1000);

  // exp *= 1000;

  // console.log(exp, 'exp');
  // console.log(currentTime, 'currentTime');

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp - currentTime;
  // console.log(timeLeft*100, 'timeLeft');

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Token expired');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiry');

    window.location.href = PATH_AUTH.login;
    // console.log(timeLeft*100);
  }, timeLeft*100); // 3 days
};

const setSession = (accessToken, accessTokenExpiry, user) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('accessTokenExpiry', accessTokenExpiry);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // ~3 days by minimals server
    handleTokenExpired(accessTokenExpiry);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
