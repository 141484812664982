/* eslint-disable prettier/prettier */
import axios from "../utils/axios";
import { config } from "./config";


const getSalesPersonList = async()=>{
    const data = await axios.get("/api/v1/user/sales_persons_list",config)
    return data.data
}

const addSalesPerson = async(body)=>{
    const data = await axios.post("/api/v1/user/create_sales_person",body,config)
    return data.data
}
const updateSalesPerson = async(body)=>{
    const data = await axios.put("/api/v1/user/update_sales_person",body,config)
    return data.data
}
const deleteSalesPerson = async(id)=>{
    const data = await axios.delete(`/api/v1/user/delete_sales_person/${id}`,config)
    return data.data
}
const getSalespersonById = async(id)=>{
    const data = await axios.get(`/api/v1/user/sales_persons_list_by_id/${id}`,config)
    return data.data
}

 export const salesPersonService = {
    getSalesPersonList,
    addSalesPerson,
    deleteSalesPerson,
    updateSalesPerson,
    getSalespersonById
}