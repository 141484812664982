/* eslint-disable prettier/prettier */
import axios from "../utils/axios";
import { config } from "./config";


const getEventList = async()=>{
    // console.log(config, "Header_________");
    const data = await axios.get("/api/v1/user/events_list",config)
    return data.data;
}

const addEvent = async(body)=>{
    const data = await axios.post("/api/v1/user/create_events",body,config)
    return data.data
}
const updateEvent = async(body)=>{
    const data = await axios.put("/api/v1/user/update_events",body,config)
    return data.data
}
const deleteEvent = async(id)=>{
    const data = await axios.delete(`/api/v1/user/delete_events/${id}`,config)
    return data.data
}
const GetEventById = async(id)=>{
    const data = await axios.get(`/api/v1/user/events_list_by_id/${id}`,config)
    return data.data
}

 export const eventService = {
    getEventList,
    addEvent,
    updateEvent,
    deleteEvent,
    GetEventById
}