import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import PackageView from '../pages/dashboard/PackageView';
import SalespersonView from '../pages/dashboard/SalespersonView';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [redirectPath, setRedirectPath] = useState(PATH_AFTER_LOGIN);
  const { user, logout } = useAuth();
  console.log(user, 'user')
  const flag = !user?.isEmailConfigured;

  useEffect(() => {
    if(user?.status === 0 || user?.isDeleted === 1) {
      logout();
    }
  }, [user, logout])

  // if(!flag){
  //   setRedirectPath(PATH_DASHBOARD.profile.ConfigEmail)
  // }else{
  //   setRedirectPath(PATH_AFTER_LOGIN)
  // }
    
  return useRoutes([
    
    { path: 'payments/success/:id', element: <PaymentSuccess /> },
    { path: 'payments/cancel/:id', element: <PaymentCancel /> },
   
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: ':pageId/:token', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={user?.roleId === 1 ? '/dashboard/organization/list' : redirectPath} replace />,
          index: true,
        },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },

 

        // QuoteManager
        {
          path: 'quotes',
          children: [
            { element: <Navigate to="/dashboard/quotes/list" replace />, index: true },
            { path: 'list', element: <QuoteList /> },
            { path: 'view/:id', element: <QuoteView /> },
            { path: 'edit/:id', element: <QuoteCreate /> },
            { path: 'new', element: <QuoteCreate /> },
          ],
        },
        {
          path: 'events',
          children: [
            { element: <Navigate to="/dashboard/events/list" replace />, index: true },
            { path: 'list', element: <EventList /> },
            { path: 'edit/:id', element: <EventCreate /> },
            { path: 'new', element: <EventCreate /> },
          ],
        },
        {
          path: 'products',
          children: [
            { element: <Navigate to="/dashboard/products/list" replace />, index: true },
            { path: 'list', element: <ProductList /> },
            { path: 'view/:id', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'packages',
          children: [
            { element: <Navigate to="/dashboard/packages/list" replace />, index: true },
            { path: 'list', element: <PackageList /> },
            { path: 'edit/:id', element: <PackageCreate /> },
            { path: 'view/:id', element: <PackageCreate /> },
            { path: 'new', element: <PackageCreate /> },
          ],
        },
        {
          path: 'salesperson',
          children: [
            { element: <Navigate to="/dashboard/salesperson/list" replace />, index: true },
            { path: 'list', element: <Salesperson /> },
            { path: 'edit/:id', element: <CreateSalesperson /> },
            { path: 'view/:id', element: <SalespersonView /> },
            { path: 'new', element: <CreateSalesperson /> },
          ],
        },

        {
          path: 'organization',
          children: [
            { element: <Navigate to="/dashboard/organization/list" replace />, index: true },
            { path: 'list', element: <OrganisationList /> },
          ],
        },

        {
          path: 'profile',
          children: [
            { element: <Navigate to="/dashboard/profile/myProfile" replace />, index: true },
            { path: 'myProfile', element: <MyProfile /> },
            { path: 'changePassword', element: <ChangePassword /> },
            { path: 'changeStripe', element: <ChangeStripe /> },
            // { path: 'configEmail', element: <ConfigEmail /> },
            { path: 'configEmail', element: <RoleBasedGuard roles={[2]} hasContent><ConfigEmail/></RoleBasedGuard> }

          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   // element: <DashboardLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    {
      path: '/',
      element: <Navigate to={user?.roleId === 1 ? PATH_DASHBOARD.organization.list : PATH_DASHBOARD.quotes.list} replace />,
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// QuoteManager

// Quotes
const QuoteList = Loadable(lazy(() => import('../pages/dashboard/quotesPages/QuoteList')));
const QuoteCreate = Loadable(lazy(() => import('../pages/dashboard/quotesPages/QuoteCreate')));
const QuoteView = Loadable(lazy(() => import('../pages/dashboard/quotesPages/QuoteView')));

// Events
const EventList = Loadable(lazy(() => import('../pages/dashboard/EventList')));
const EventCreate = Loadable(lazy(() => import('../pages/dashboard/EventCreate')));

// Products
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));

// Packages
const PackageList = Loadable(lazy(() => import('../pages/dashboard/Packagelist')));
const PackageCreate = Loadable(lazy(() => import('../pages/dashboard/PackageCreate')));
const Packageview = Loadable(lazy(() => import('../pages/dashboard/PackageView')));

// Salesperson

const Salesperson = Loadable(lazy(() => import('../pages/dashboard/Salesperson')));
const CreateSalesperson = Loadable(lazy(() => import('../pages/dashboard/SalespersonCreate')));

// Profile

const MyProfile = Loadable(lazy(() => import('../pages/dashboard/MyProfile')));
const ChangeStripe = Loadable(lazy(() => import('../pages/dashboard/ChangeStripe')));
const ConfigEmail = Loadable(lazy(() => import('../pages/dashboard/ConfigEmail')));
const ChangePassword = Loadable(lazy(() => import('../pages/dashboard/ChangePassword')));

// Oranisation

const OrganisationList = Loadable(lazy(() => import('../pages/dashboard/OrganisationList')));

// Payment
const PaymentSuccess = Loadable(lazy(() => import('../pages/Payments/PaymentSuccess')));
const PaymentCancel = Loadable(lazy(() => import('../pages/Payments/PaymentCancel')));
